<template>
    <div class="content">
        <header>
            <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ $t("navigation.contact") }}</h1>
            <p class="is-size-4-desktop is-size-5-tablet">
                {{ $t("message.contact.contact_desc") }}
            </p>
        </header>
        <main>
            <section class="section contact is-inline-block my-6">
                <form @submit.prevent="onSubmit()">
                    <form-input type="text" name="name"
                        ref="name"
                        :caption="$t('message.contact.name')"
                        icon="user"
                        :mandatory="true"
                        @input="(value) => {name = value}"
                        :value="name"
                        :maxlength="64"
                        :errors="errors.name" />
                    <form-input type="text" name="firstname"
                        ref="firstname"
                        :caption="$t('message.contact.firstname')"
                        icon="user"
                        :mandatory="false"
                        @input="(value) => {firstname = value}"
                        :value="firstname"
                        :maxlength="64" />
                    <form-input type="text" name="email"
                        ref="email"
                        format="email"
                        :caption="$t('message.contact.email')"
                        icon="envelope"
                        :mandatory="true"
                        @input="(value) => {email = value}"
                        :value="email"
                        :maxlength="64"
                        :errors="errors.email" />
                    <form-input type="textarea" name="content"
                        ref="content"
                        :caption="$t('message.contact.content')"
                        :mandatory="true"
                        @input="(value) => {message = value}"
                        :value="message"
                        :errors="errors.message" />
                    <vue-hcaptcha
                        ref="invisibleHcaptcha"
                        :sitekey="captchaToken"
                        size="invisible"
                        @verify="onVerify"
                        @expired="onExpired"
                        @error="onError"
                        @challengeExpired="onExpired"></vue-hcaptcha>

                    <div class="box has-text-success has-text-weight-semibold" v-if="contactMessage !== ''">
                        <span>{{ contactMessage }}</span>
                    </div>
                    <div class="box has-text-danger has-text-weight-semibold"  v-if="errorMessage !== ''">
                        <span>{{ errorMessage }}</span>
                    </div>

                    <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                        <form-button type="submit"
                            :caption="$t('message.validate')" />
                    </div>
                </form>
            </section>
        </main>
    </div>
</template>

<script>
/**
 * Contact view
 * Display the Contact page
 * @author Alba IT
 * @version 1.0.0
 */
//import { Email } from '@/assets/vendors/smtpjs/smtp';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

import Config from '@/config';

import FormButton from '@/components/layout/controls/form/FormButton.vue';
import FormInput from '@/components/layout/controls/form/FormInput.vue';

export default {
    name: 'Contact',
    components: {
        FormButton,
        FormInput,
        VueHcaptcha
    },
    data: function() {
        // Fields references for validation check
        this.fields = ['name', 'firstname', 'email', 'content'];
        return {
            // Form data
            name: '',
            firstname: '',
            email: '',
            message: '',
            // Validation errors
            errors: {
                name: [],
                email: [],
                message: []
            },
            contactMessage: '',
            errorMessage: '',
            // hCaptcha specific data
            captchaToken: Config.captcha.token,
            verified: false,
            expired: false,
            token: null,
            eKey: null,
            error: null
        };
    },
    computed: {
        /**
         * Generate the contact message HTML template
         * @returns {string}
         */
        generateContactMessage: function() {
            return `
                <h1>Seenjab - Formulaire de contact</h1>
                <br />
                <h3>Nom / Entreprise : ${this.name}</h3>
                <h3>Prénom : ${this.firstname}</h3>
                <h3>Email : ${this.email}</h3>
                <h3>Message :</h3>
                <p>
                    ${this.message}
                </p>
            `;
        }
    },
    methods: {
        /**
         * Validate the field data and generate the validation errors
         * @returns {Promise}
         */
        validate: function() {
            return new Promise((resolve, reject) => {
                for (let i = 0; i < this.fields.length; i++) {
                    const field = this.$refs[this.fields[i]];

                    field.validate();
                    if (!field.valid) {
                        return reject(false);
                    }
                }
                return resolve(true);
            });
        },
        /**
         * Send the email
         * @returns {void}
         */
        send: function() {
            this.contactMessage = this.$t("message.contact.sent");
            // If the captcha is verified and not expired
            if (this.verified && !this.expired) {
                // Check the hCaptcha token validity
                fetch(`${Config.url}/ajax/hcaptcha.php`, {
                    method: "POST",
                    body: JSON.stringify({
                        token: this.token
                    })
                })
                .then((response) => response.json())
                .then((result) => {
                    // If the hCaptcha verification succeeded
                    if (result.success) {
                        fetch(`${Config.url}/ajax/sendmail.php`, {
                            method: "POST",
                            body: JSON.stringify({
                                token: Config.email.token,
                                name: this.name,
                                firstname: this.firstname,
                                email: this.email,
                                message: this.message
                            })
                        })
                        .then((response) => {
                            if (response.ok) {
                                this.contactMessage = this.$t("message.contact.sent");
                            } else {
                                this.errorMessage = this.$t("message.contact.error");
                            }
                        });

                        // Send the contact email
                        /*Email.send({
                            SecureToken : Config.email.token,
                            To : "cm@alba-it.ch",
                            From : this.email,
                            Subject : "Seenjab - Formulaire de contact",
                            Body : this.generateContactMessage
                        }).then((response) => {
                            if (response === 'OK') {
                                // Send the email confirmation
                                Email.send({
                                    SecureToken : Config.email.token,
                                    To : this.email,
                                    From : "christophe.mattern@alba-it.ch",
                                    Subject : "Seenjab - Formulaire de contact - Confirmation",
                                    Body : this.generateContactMessage
                                }).then((response) => {
                                    if (response === 'OK') {
                                        alert(this.$t("message.contact.sent"));
                                    }
                                });
                            }
                        });*/
                    } else {
                        this.errorMessage = this.$t("message.contact.error");
                    }
                });
            } else {
                this.errorMessage = this.$t("message.contact.error");
            }
        },
        // hCaptcha events
        /**
         * On form submit
         * @returns {void}
         */
        onSubmit: function() {
            // Validate the form
            this.validate()
            .then(() => {
                // If the validation is sucessful, init hCaptcha
                this.$refs.invisibleHcaptcha.execute();
            })
            .catch(() => console.log("Validation failed."));
        },
        /**
         * On hCaptcha challenge verification
         * @returns {void}
         */
        onVerify: function(token, ekey) {
            this.verified = true;
            this.token = token;
            this.eKey = ekey;
            //console.log(`Callback token: ${token}, ekey: ${ekey}`);

            // Send the email
            this.send();
        },
        /**
         * On hCaptcha challenge expiration
         * @returns {void}
         */
        onExpired: function() {
            this.verified = false;
            this.token = null;
            this.eKey = null;
            this.expired = true;
            //console.log('Expired');
        },
        /**
         * On hCaptcha challenge error
         */
        onError: function(err) {
            this.token = null;
            this.eKey = null;
            this.error = err;
            //console.log(`Error: ${err}`);
        }
    },
    /**
     * Meta data
     * @returns {object}
     */
    metaInfo: function() {
        return {
            title: `${this.$t("message.contact.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.contact.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'og:title',
                    content: `${this.$t("message.contact.meta.title")} | ${this.$t('message.title')}`
                },
                {
                    name: 'og:image',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:secure_url',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:type',
                    content: 'image/png'
                },
                {
                    name: 'og:image:alt',
                    content: 'Seenjab logo small'
                },
                {
                    name: 'og:description',
                    content: this.$t('message.contact.meta.description'),
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

@media(min-width: $desktop-landscape) {
    .contact {
        width: 30%;
    }
    .alba {
        width: 50%;
        margin: auto;
    }
}
</style>
